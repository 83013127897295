import { createApp } from 'vue'
import App from './App.vue'
import "@/assets/style/tailwind.css"
import {Button, Divider, Input, Modal, Spin} from "ant-design-vue"
import "ant-design-vue/lib/message/style/index.less"

createApp(App)
    .use(Modal)
    .use(Input)
    .use(Spin)
    .use(Divider)
    .use(Button)
    .mount('#app')
